import React, {useState, useEffect} from 'react';
import Sequence from "./Sequence.js";

let sequencesstart = [   
    {numbers : [1,3,5,7,9], status : 0},
    {numbers : [2,4,6,8] , status : 0},
    {numbers : [10,12,14,16,18], status : 0},
    {numbers : [11,13,15,17,19], status : 0},
    {numbers : [21,23,25,27,29], status : 0},
    {numbers : [30,32,34,36], status : 0},
    {numbers : [20,22,24,26,28], status : 0},
    {numbers : [1,11,21,31], status: 0},
    {numbers : [2,12,22,32], status: 0},
    {numbers : [3,13,23,33], status: 0},
    {numbers : [4,14,24,34], status: 0},
    {numbers : [5,15,25,35], status: 0},
    {numbers : [6,16,26,36], status: 0},
    {numbers : [0,10,20,30], status: 0}];

    let values = [];
    let candidates = [];
    const N = 37;
    const NOTAVAILABLE = 0;
    const COLD = 1;
    const PARTIALCOLD = 2;
    const COMING = 3;
    const HOT = 4;

function Initialize(){
    for(let i=0; i<N; i++){
        candidates[i] = {number: i, counter : 0}
        values[i] = 0
    } 
}

async function paste(sequences, setSequences, numbers, setNumbers) {

    let arr = [];
    let txt = "";
    await navigator.clipboard.readText().then(text => txt = text);
    arr = (txt.match(/number-\d+/g) || []).map(n => parseInt(n.substring(7)));
    return arr;
}



function calculateSequences(sequences, setSequences){
    setSequences(sequences.map(s => 
    {
        s.status = HOT;
        for(let n of s.numbers){
            if (candidates[n].counter >= 25  && candidates[n].counter <= 34)
                s.status = COMING;
            else if (!IsValid(candidates[n].counter)) {
                s.status = NOTAVAILABLE;
                break;
                }
            else if (IsCold(candidates[n].counter) && s.status != COMING)
                s.status = s.status == HOT ? PARTIALCOLD : COLD;
            };
        return s;
    }))
}

function IsValid(counter){
    return counter >= 35;
}
function IsCold(counter){
    return counter >= 85;
}

function Roulette() {
    const [numbers, setNumbers] = useState([]);
    const [currentNumber, setCurrentNumber] = useState([]);
    const [sequences, setSequences] = useState(sequencesstart);
    const Addnumber = (n) => {
        values[n] = numbers.length;
        setNumbers((numbers) =>  [...numbers,n]);
        debugger
        let count = numbers.length;
        for (let i = 0; i < N; i++)
        {
               candidates[i].counter = count - values[i];
        }
        calculateSequences(sequences, setSequences)
    }
    useEffect(() =>{ Initialize()}, [null]);
    return (
    <div class="h-full w-full bg-black shadow-irene rounded-md p-2 flex flex-shrink flex-col items-center space-y-2 overflow-hidden">
        <div class= "flex h-full  w-full flex-shrink space-x-2">
            <div class= "border-2 border-truegray-800  flex flex-1 flex-shrink h-full w-full flex-col min-w-0 min-h-0  items-center p-2 space-y-2">
                <p class ="text-white flex flex-shrink text-3x1 align-middle font-black w-full pl-2">Inserisci numero</p>
                <div class="flex flex-shrink w-full space-x-2 p-2">
                    <input class = " flex bg-gray-300 flex-shrink"  onChange={(e) => setCurrentNumber(e.target.value)}></input>
                    <button class="bg-black hover:bg-gray-500 text-white font-bold py-2 px-4  flex min-w-0 min-h-0 flex-shrink overflow-hidden" onClick={() => Addnumber(currentNumber)}>Aggiungi</button>
                    <button class="bg-black hover:bg-gray-500 text-white font-bold py-2 px-4  flex min-w-0 min-h-0 flex-shrink overflow-hidden" onClick={() => setNumbers(numbers.slice(0,-1))}>Annulla</button>
                </div>
                <button class="bg-black hover:bg-gray-500 text-white font-bold py-2 px-4  min-w-0 min-h-0 w-full flex flex-shrink" onClick={async () => {let x = await paste(); x.forEach(k=> Addnumber(k))}}>Inserisci numeri</button>
                <button class="bg-black hover:bg-gray-500 text-white font-bold py-2 px-4  min-w-0 min-h-0 w-full flex flex-shrink" >Annulla</button>
            </div>
            <div class= " p-2 border-2 border-truegray-800 flex flex-1 flex-shrink h-full min-w-0 min-h-0 w-full flex-col items-center space-y-1 ">
                <p class ="flex-shrink flex text-white text-center  text-2xl font-black w-5/6 m-auto w-auto">Sequenze in arrivo</p>
                <div class="bg-gray-300 flex  w-full h-full break-normal flex-grow p-2 min-w-0 min-h-0 flex-shrink flex-row flex-wrap space-x-2 items-start">
                    {sequences.map(s => s.status == COMING ? (<Sequence numbers= {s.numbers} status = {s.status}> </Sequence>) : null)}
                </div>
            </div>
            <div class= " p-2 border-2 border-truegray-800 flex flex-1 flex-shrink flex-grow min-w-0 min-h-0 h-full w-full flex-col items-center space-y-1 ">
                <p class ="flex flex-shrink text-white text-center  text-2xl font-black w-5/6 m-auto w-auto">Possibili sequenze giocabili</p>
                <div class="bg-gray-300 flex  w-full h-full break-normal flex-grow p-2 min-w-0 min-h-0 flex-shrink flex-row flex-wrap space-x-2 items-start">
                    {sequences.map(s => s.status != COMING ?  (<Sequence numbers= {s.numbers} status = {s.status} key={s.numbers.toString()}> </Sequence>) : null)}
                </div>
            </div>
        </div>
        <div class= "border-2 border-truegray-800 flex h-full w-full  flex-col flex-shrink min-w-0 min-h-0">
            <div class= " flex-shrink  h-full w-full flex flex-col items-center justify-center p-2 space-y-2">
                <p class ="flex flex-shrink text-white text-center  text-4xl font-black  ">Ultimi usciti</p>
                <p class="bg-gray-300 flex  w-full h-full flex-shrink break-normal flex-grow p-2" >{numbers.map(n => " " + n)}</p>
            </div>
        </div>

    </div>
    )
}

export default Roulette
