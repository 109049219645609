import React from 'react'
import {ReactComponent as Log} from './scritta.svg';
import { Link, BrowserRouter as Router } from 'react-router-dom'

export default function Navbar2({currentPage, setCurrentPage}) {
   // <p class="text-white flex text-4xl text-center self-center m-auto " >PLTNVMEXPERIENCE</p>

    return (
        <div class="sticky flex  h-auto w-full justify-between flex-col px-4 ">
                <div class="flex self-center flex-shrink ">
                    <Log class=" flex flex-shrink self-center"/>
                </div>
                <div class=" p-2 rounded-xl flex flex-shrink space-x-32 justify-center ">
                    <Router>
                    <button class={(currentPage == "Sport" ? " " : " ") +    "transition duration-500 ease-in-out m-4 border-b-2 border-opacity-0 hover:border-opacity-100 border-white  items-center content-center h-auto text-white text-center font-San-Francisco  text-4xl"} onClick={() => setCurrentPage("Sport")}>SPORT</button>
                    <button class={(currentPage == "Roulette" ? " " : " ") + "m-4 border-b-2 border-opacity-0 hover:border-opacity-100 border-white  items-center content-center h-auto text-white text-center font-San-Francisco  text-4xl"} onClick={() => setCurrentPage("Roulette")}>ROULETTE</button>
                    </Router>
                </div>
        </div>
    )
}
