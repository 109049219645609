
function Display(props){
    return <div class="self-center align-middle mx-auto my-auto  box-content overflow-hidden h-3/5 w-3/5  p-20 bg-bluegray-200 rounded-xl shadow-md flex items-center">
    <div class="flex-shrink-0">
    </div>
    <div>
      <div class="text-xl font-medium text-black"></div>
      <p class="text-gray-500"></p>
    </div>

  </div>

}
export default Display;
