import './App.css';
//import './index.css' 
import NavBar from './NavBar';
import Display from './Display';
import Navbar2 from './Navbar2';
import Roulette from './Roulette';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createContext , useState, useEffect} from 'react';
import Sport from './Sport';
import firebase from 'firebase/compat/app';
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Login from './Login';

let db = null;

function Initialize(){
	const firebaseConfig = {
		apiKey: "AIzaSyBmvwj98aSSUtBxHZznL9OL4NrUj_tpc3I",
		authDomain: "pltnvmexperience.firebaseapp.com",
		projectId: "pltnvmexperience",
		storageBucket: "pltnvmexperience.appspot.com",
		messagingSenderId: "249560512199",
		appId: "1:249560512199:web:e2a50e70559a498b75f0e2",
		measurementId: "G-4ZDF3RSXTX"
	  };
	  
	  // Initialize Firebase
	firebase.initializeApp(firebaseConfig);
	db = firebase.firestore();

	/*firebase.auth().createUserWithEmailAndPassword("leonardo.pennino98@gmail.com", "torino2021").then((uid) => console.log(uid)).catch((error) => {
		var errorCode = error.code;
		var errorMessage = error.message;
		console.log(error.code);
	  }); */
}

function App() {
	const [currentPage, setcurrentPage] = useState("Roulette");
	const [logged, setLogged] = useState(null);
	useEffect(() =>{ Initialize()}, [null])
	/*firebase.auth().onAuthStateChanged((user) => {
		if (user) {
		  logged = true
		} else {
		  logged = false;
		}
	  });*/
	return (
			<div className="App" class ="flex flex-grow flex-col h-full w-full bg-gradient-to-b from-truegray-800 to-black">
				<Navbar2 currentPage={currentPage} setCurrentPage={setcurrentPage}/>
				<div class="flex h-full w-full  m-0 p-10 items-center overflow-auto flex-grow flex-shrink">
					{(() => {
					if(logged == null)
						return (<Login setloggedin={setLogged} firebase={firebase}></Login>);
					if (currentPage == "Roulette") 
						return (<Roulette></Roulette>);
					else if (currentPage == "Sport")
						return (<Sport db={db} uid={logged}/>);
				})()}
				</div>
			</div>
	);
}

export default App;
