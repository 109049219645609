import React from 'react'
import {useState} from 'react'

function Signin(user,pass,firebase,setloggedin,setError){
    firebase.auth().createUserWithEmailAndPassword(user,pass).then((uid) => {setloggedin(uid.user.uid);}).catch((error) => {
		var errorCode = error.code;
		var errorMessage = error.message;
		console.log(error.code);
        setError(errorMessage);
        setloggedin(false);
	  }); 
}

function Log(user,pass,firebase,setloggedin,setError){
    firebase.auth().signInWithEmailAndPassword(user,pass).then((uid) =>{setloggedin(uid.user.uid);}).catch((error) => {
		var errorCode = error.code;
		var errorMessage = error.message;
        console.log(errorCode);
		if(errorCode == "auth/wrong-password")
            setError("La password inserita non è corretta");
        else if (errorCode == "auth/email-already-in-use")
            setError("La mail selezionata è già in uso");
        else if (errorCode == "auth/user-not-found")
            setError("Nessun utente associato a questa mail");
        setloggedin(false);
	  }); 
}

function Login({setloggedin, firebase}) {
    const [user, setuser] = useState("")
    const [password, setpassword] = useState("")
    const [error, setError] = useState("");
    return (
        <div class="h-full w-full  p-8  rounded-lg shadow-md flex flex-shrink flex-col items-center space-y-2">
<div class="w-full max-w-xs">
  <form class=" shadow-md rounded px-8 pt-6 pb-8 mb-4">
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
        EMAIL
      </label>
      <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Username" onChange={(e) => setuser(e.target.value)}/>
    </div>
    <div class="mb-6">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
        Password
      </label>
      <input class="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="" onChange={(e) => setpassword(e.target.value)}/>
      <p class="text-red-500 text-xs italic">{error}</p>
    </div>
    <div class="flex items-center justify-between">
      <button class="bg-black hover:bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={() =>Log(user,password,firebase,setloggedin,setError)}>
        Accedi
      </button>
      <button class="bg-black hover:bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={() =>Signin(user,password,firebase,setloggedin,setError)}>
        Registrati
      </button>

    </div>
  </form>
  <p class="text-center text-gray-500 text-xs">
    &copy;2020 PLTNVM.
  </p>
</div>
        </div>
    )
}

export default Login
