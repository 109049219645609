import React from 'react'

function Game({game}) {
    console.log(game);
    return (
        <>
            <p class="bg-black  text-white font-San-Francisco py-2 px-4  flex min-w-0 min-h-0 flex-shrink overflow-hidden">{game.Data}</p>
            <p class="bg-black  text-white font-San-Francisco py-2 px-4  flex min-w-0 min-h-0 flex-shrink overflow-hidden">{game.Orario}</p>
            <p class="bg-black  text-white font-San-Francisco py-2 px-4  flex min-w-0 min-h-0 flex-shrink overflow-hidden">{game.SPORT}</p>
            <p class="bg-black  text-white font-San-Francisco py-2 px-4  flex min-w-0 min-h-0 flex-shrink overflow-hidden">{game.Partita}</p>
            <p class="bg-black  text-white font-San-Francisco py-2 px-4  flex min-w-0 min-h-0 flex-shrink overflow-hidden">{game.Pronostico}</p>
            <p class="bg-black  text-white font-San-Francisco py-2 px-4  flex min-w-0 min-h-0 flex-shrink overflow-hidden">{game.Quota}</p>
            <p class="bg-black  text-white font-San-Francisco py-2 px-4  flex min-w-0 min-h-0 flex-shrink overflow-hidden">{game.Book}</p>
            <p class="bg-black  text-white font-San-Francisco py-2 px-4  flex min-w-0 min-h-0 flex-shrink overflow-hidden">{game.Stake}</p>
        </>
    )
}

export default Game
