import React, { useState, useEffect } from 'react'
import Game from './Game';




function Sport({db,uid}) {
    const [arr, setarr] = useState([]);
    const getMatches = async () => {
        const numbers = generate();
        let n = await db.collection("User").doc(uid).get();
        if(n.exists && typeof n.data().Partite !=='undefined'){
          debugger
          let partite = n.data().Partite;
          let tmp = []
          for(let k =0; k<3; k++){
            await db.collection("Partite").doc(partite[k] +"").get().then(qs =>{tmp.push(qs.data())})
          }
          setarr(tmp);
          return;
        }
        let tmp =[];
        await db.collection("User").doc(uid).update({Partite:numbers});
        for(let k =0; k<3; k++){
          await db.collection("Partite").doc(numbers[k] +"").get().then(qs =>{tmp.push(qs.data())})
        }
        setarr(tmp);
        return;   
    }
 
    return (
        <div class="h-full w-full  p-2  rounded-lg  flex flex-shrink flex-col justify-items-center items-center space-x-4 space-y-8">
            <button class="bg-black border-b-2 border-opacity-0 hover:border-opacity-100 border-white text-truegray-300 font-San-Francisco font-bold p-4 text-center flex m-0 w-auto " onClick={() => getMatches(db) }>Genera Partite</button>
            <div class= "grid gap-x-2 grid-cols-8 bg-black shadow-irene rounded-md p-2">
                <p class="bg-black text-truegray-300 font-San-Francisco text-xl font-bold py-2 px-4  flex min-w-0 min-h-0 flex-shrink overflow-hidden">Data</p>
                <p class="bg-black text-truegray-300 font-San-Francisco text-xl font-bold py-2 px-4  flex min-w-0 min-h-0 flex-shrink overflow-hidden">Orario</p>
                <p class="bg-black text-truegray-300 font-San-Francisco text-xl font-bold py-2 px-4  flex min-w-0 min-h-0 flex-shrink overflow-hidden">Sport</p>
                <p class="bg-black text-truegray-300 font-San-Francisco text-xl font-bold py-2 px-4  flex min-w-0 min-h-0 flex-shrink overflow-hidden">Partita</p>
                <p class="bg-black text-truegray-300 font-San-Francisco text-xl font-bold py-2 px-4  flex min-w-0 min-h-0 flex-shrink overflow-hidden">Pronostico</p>
                <p class="bg-black text-truegray-300 font-San-Francisco text-xl font-bold py-2 px-4  flex min-w-0 min-h-0 flex-shrink overflow-hidden">Quota</p>
                <p class="bg-black text-truegray-300 font-San-Francisco text-xl font-bold py-2 px-4  flex min-w-0 min-h-0 flex-shrink overflow-hidden">Book</p>
                <p class="bg-black text-truegray-300 font-San-Francisco text-xl font-bold py-2 px-4  flex min-w-0 min-h-0 flex-shrink overflow-hidden">Stake</p>
                {arr.length > 0 ? arr.map(l => <Game game={l}></Game>) : <p>asd</p>}      
            </div>

        </div>
        
    )
}

function generate(){
    var numbers = []; // new empty array
  
  var min, max, r, n, p;
  
  min = 1;
  max = 10;
  r = 3; // how many numbers you want to extract
  
  for (let i = 0; i < r; i++) {
    do {
      n = Math.floor(Math.random() * (max - min + 1)) + min;
      p = numbers.includes(n);
      if(!p){
        numbers.push(n);
      }
    }
    while(p);
  }
  return numbers;
  }
  

export default Sport
