import React from 'react'
const COLD = 1;
const PARTIALCOLD = 2;
const COMING = 3;
const HOT = 4;
function Sequence({numbers, status}) {
    if(status == HOT)
        return(
            <p class="bg-black text-white flex rounded-lg flex-grow-0  break-normal  p-2 m-1 min-w-0 min-h-0 flex-shrink">{numbers.map(n=> " " +n)}</p>
        );
    else if(status == PARTIALCOLD)
        return (
            <p class="bg-black text-white flex rounded-lg flex-grow-0  break-normal  p-2 m-1 min-w-0 min-h-0 flex-shrink">{numbers.map(n=> " " +n)}</p>
        );
    else if (status == COLD)
        return (
            <p class="bg-black text-white flex rounded-lg flex-grow-0  break-normal  p-2 m-1 min-w-0 min-h-0 flex-shrink">{numbers.map(n=> " " +n)}</p>
        );
    else if (status == COMING)
        return(
            <p class="bg-amber-600 flex rounded-lg flex-grow-0  break-normal  p-2 m-1 min-w-0 min-h-0 flex-shrink">{numbers.map(n=> " " +n)}</p>
        )
    else
        return null;
}

export default Sequence
